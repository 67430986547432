import React from "react";
import * as productsCardsStyles from "./_productCards.module.scss";
import { LocalizedLink } from "src/components/commonComponents";
/**
 * list of Cards with image, title, body and link
 * @param {Object} data -(required) {title: string, description: string, list:[{logo: StaticImage, title: string, description: string, link: string}], cta: {title: string, link: string}}
 * @param {string} linkPrefix - (required) locale specific prefix.
 *
 */

export const ProductCards = ({ data, linkPrefix, locale }) => {
  return (
    <div className={productsCardsStyles?.productCardsContainer + " content-visibility-auto-mobile tw-relative md:tw-py-[40px] tw-mx-[16px]"}>
      <div className="md:tw-ml-[calc(50%-50vw)] md:tw-mr-[calc(50%-50vw)] md:tw-h-full md:tw-left-0 md:tw-absolute md:tw-right-0 md:tw-top-0 md:tw-w-auto"></div>
      <div className="tw-flex md:tw-flex-row tw-flex-col tw-justify-between md:tw-items-center tw-items-start md:tw-gap-0 tw-gap-6">
        <div className="tw-z-[1]">
          <h2 className="title-4">{data?.title}</h2>
          {/* <div className="subtitle-2">{data?.description}</div> */}
        </div>
        {data?.cta?.link && (
          <LocalizedLink
            to={linkPrefix + data?.cta?.link}
            className="tw-font-Poppins tw-text-[16px] tw-font-bold see-all tw-border-2 tw-border-solid tw-border-link-color tw-rounded-[12px] tw-p-[14px_24px] 
            tw-block tw-text-center  tw-w-fit tw-text-accruent_sapphire 
            hover:tw-text-accruent_white hover:tw-bg-link-color see-all tw-z-[1]"
          >
            {data?.cta?.title}
          </LocalizedLink>
        )}
      </div>
      <div className="product-cards-container box-body tw-mt-[40px] tw-grid tw-gap-4 tw-overflow-y-auto
       tw-mx-[-1rem] tw-px-[1rem] md:tw-mx-[inherit] md:tw-px-[inherit]">
        {data?.list?.map((product, i) => {
          const link = (locale === 'en') ? linkPrefix + product?.link : !product?.onlyUs ? linkPrefix + product?.link  : `https://www.accruent.com${product?.link}`
          return (
            <div key={i}>
              <div className="card md:!tw-shadow-none tw-bg-accruent_white tw-bg-no-repeat tw-shadow-[3px_3px_20px_#7d7d7d29] 
                !tw-rounded-[48px_48px_48px_0px] tw-flex tw-flex-col tw-h-full tw-min-w-[278px] md:tw-min-w-full">
                <div className="card-img tw-bg-subtitle_color tw-bg-no-repeat tw-rounded-[48px_48px_0px_0px]
                tw-flex tw-justify-center tw-items-center tw-h-[100px]">
                  <div className="tw-relative">
                    {product?.logo}
                    <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0">
                      <LocalizedLink to={link} className="tw-block tw-h-full" />
                    </div>
                  </div>
                </div>

                <div className="card-body tw-m-[32px_27px_24px_16px] tw-flex-1 tw-flex tw-flex-col tw-justify-between">
                  <div>
                  <LocalizedLink to={link}>
                    <h6 className="card-title tw-font-bold tw-text-[22px] tw-leading-[25px] tw-text-heading-color tw-font-Roboto">{product?.title}</h6>
                    </LocalizedLink>
                    <p className="card-text tw-font-Roboto tw-mt-[15px] tw-mb-[32px] tw-text-4 tw-leading-[21px] tw-text-accruent_black2">{product?.description}</p>
                  </div>
                  <LocalizedLink to={link}>
                  <span className="tw-font-Poppins tw-text-[16px] tw-font-bold tw-text-accruent_sapphire">{"Learn More  ›"}</span>
                  </LocalizedLink>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    
    </div>
  );
};
