import React from "react";
import corporate from "../images/industries/IndustryIcons/color/Industry-icons-2022_corporate_gradient.svg";
import education from "../images/industries/IndustryIcons/color/Industry-icons-2022_education_gradient.svg";
import healthcare from "../images/industries/IndustryIcons/color/Industry-icons-2022_healthcare_gradient.svg";
import manufacture from "../images/industries/IndustryIcons/color/Industry-icons-2022_manufacturing_gradient.svg";
import retail from "../images/industries/IndustryIcons/color/Industry-icons-2022_retail_gradient.svg";
import corporateWhite from "../images/industries/IndustryIcons/dualcolor/Industry-icons-2022_CRE_dualtone.svg";
import educationWhite from "../images/industries/IndustryIcons/dualcolor/Industry-icons-2022_Education_dualtone.svg";
import healthcareWhite from "../images/industries/IndustryIcons/dualcolor/Industry-icons-2022_healthcare_duotone.svg";
import manufactureWhite from "../images/industries/IndustryIcons/dualcolor/Industry-icons-2022_Industrial_dualtone.svg";
import retailWhite from "../images/industries/IndustryIcons/dualcolor/Industry-icons-2022_Retail_dualtone.svg";
import { StaticImage } from "gatsby-plugin-image";
const mcLogo = "../../static/home/Products/mc.png";
const emsLogo = "../../static/home/Products/ems.png";
const merdianLogo = "../../static/home/Products/meridian.png";
const lucernexLogo = "../../static/home/Products/lucernex.png";
const redEyeLogo = "../../static/home/Products/RedEye-Accruent-Logo.jpg";

const hospital = "../../static/Assets_Version_A/logos/bay-hospital.png"
const gallery = "../../static/Assets_Version_A/logos/master-gallery.png"
const chubbys = "../../static/Assets_Version_A/logos/chubbys.png"
const Minnesota = "../../static/Assets_Version_A/logos/Minnesota.png"

const essex = "../../static/Assets_Version_A/logos/Essex_County_Council.png"
const basf = "../../static/Assets_Version_A/logos/Image 22.png"
const total = "../../static/Assets_Version_A/logos/Total-Energies-logo.png"
const coop = "../../static/Assets_Version_A/logos/Image 23.png"

export const heroData = {
    title: "Comprehensive software for your facilities, assets, and people",
    description: "Drive effortless customer experiences, maximize growth, and unlock your business’ full potential with Accruent.",
}

export const heroImagePaths = [
    "home/Herobgs/corporate.jpg",
    "home/Herobgs/manufacturing.jpg",
    "home/Herobgs/retail.jpg",
    "home/Herobgs/healthcare.jpg",
    "home/Herobgs/higheducation.jpg",
    "home/Herobgs/realestate.jpg",
  ]

export const industryCards = [
    { color: corporate, white: corporateWhite, title: "Corporate" },
    {
      color: manufacture,
      white: manufactureWhite,
      title: "Manufacturing, Industrial and Utilities",
    },
    { color: retail, white: retailWhite, title: "Retail" },
    { color: healthcare, white: healthcareWhite, title: "Healthcare" },
    { color: education, white: educationWhite, title: "Higher Education" },
    {
      color: corporate,
      white: corporateWhite,
      title: "Real Estate Projects and Leasing",
    },
  ];

//   Solutions

 export const solutionsListUS = [
  {title: "Corporate Solutions",
  list: 
  [
    {
      title: "Real Estate Transaction Management",
      link: "/solutions/real-estate-transaction-management-software",
    },
    {
      title: "Energy Management",
      link: "/solutions/energy-management-software",
    },
    {
      title: "Desk and Room Booking Software",
      link: "/solutions/desk-room-booking-software",
    },
    {
      title: "Lease Administration Software",
      link: "/solutions/lease-administration-software",
    },
    {
      title: "Market Planning  & Site Selection",
      link: "/solutions/market-planning-site-selection-software",
    },
    {
      title: "Event Management",
      link: "/solutions/event-management-software",
    },
    {
      title: "Space Planning",
      link: "/solutions/space-planning-software",
    },
    {
      title: "Facility Asset Management",
      link: "/solutions/facility-asset-management-software",
    },
  ],
    },
    {title: "Manufacturing, Industrial  And Utilities Solutions",
    list:
    [
      {
        title: "Enterprise Asset Management",
        link: "/solutions/enterprise-asset-management-software",
      },
      {
        title: "CMMS",
        link: "/solutions/cmms-software",
      },
      {
        title: "Lease Administration Software",
        link: "/solutions/lease-administration-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "IWMS",
        link: "/solutions/iwms-software",
      },
      {
        title: "Field Service Management",
        link: "/solutions/field-service-management-software",
      },
      {
        title: "Engineering Document Management",
        link: "/solutions/engineering-document-management-software",
      },
      {
        title: "IoT Remote Monitoring",
        link: "/solutions/iot-remote-monitoring-software",
      },
    ],
  },
  {title: "Retail Solutions",
  list:
    [
      {
        title: "Facility Asset Management",
        link: "/solutions/facility-asset-management-software",
      },
      {
        title: "Real Estate Transaction Management",
        link: "/solutions/real-estate-transaction-management-software",
      },
      {
        title: "Lease Accounting Software",
        link: "/solutions/lease-accounting-software",
      },
      {
        title: "Enterprise Refrigeration Management",
        link: "/solutions/enterprise-refrigeration-management-software",
      },
      {
        title: "Market Planning  & Site Selection",
        link: "/solutions/market-planning-site-selection-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "Project Management",
        link: "/solutions/project-management-software",
      },
      {
        title: "IoT Remote Monitoring",
        link: "/solutions/iot-remote-monitoring-software",
      },
    ],
  },
  {title: "Healthcare Solutions",
  list:
    [
      {
        title: "Healthcare Technology Management",
        link: "/solutions/healthcare-technology-management-software",
      },
      {
        title: "Medical Equipment Capital Planning",
        link: "/solutions/medical-equipment-planning-software",
      },
      {
        title: "Enterprise Refrigeration Management",
        link: "/solutions/enterprise-refrigeration-management-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "IoT Remote Monitoring",
        link: "/solutions/iot-remote-monitoring-software",
      },
      {
        title: "CMMS",
        link: "/solutions/cmms-software",
      },
    ],
  },
  {title: "Higher Education  Solution",
  list:
    [
      {
        title: "Desk and Room Booking Software",
        link: "/solutions/desk-room-booking-software",
      },
      {
        title: "Facility Asset Management",
        link: "/solutions/facility-asset-management-software",
      },
      {
        title: "Project Management",
        link: "/solutions/project-management-software",
      },
      {
        title: "IoT Remote Monitoring",
        link: "/solutions/iot-remote-monitoring-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "Event Management",
        link: "/solutions/event-management-software",
      },
      {
        title: "Energy Sustainability Management",
        link: "/solutions/energy-sustainability-management-software",
      },
    ],
  },
  {title: "Real Estate Projects And Leasing Solutions",
  list:
    [
      {
        title: "Real Estate Transaction Management",
        link: "/solutions/real-estate-transaction-management-software",
      },
      {
        title: "Lease Accounting Software",
        link: "/solutions/lease-accounting-software",
      },
      {
        title: "Lease Administration Software",
        link: "/solutions/lease-administration-software",
      },
      {
        title: "Market Planning & Site Selection",
        link: "/solutions/market-planning-site-selection-software",
      },
      {
        title: "Space Planning",
        link: "/solutions/space-planning-software",
      },
      {
        title: "Field Service Management",
        link: "/solutions/field-service-management-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "IWMS",
        link: "/solutions/iwms-software",
      },
    ],
  }
  ];
  
  export const solutionsListUK = [
    {title: "Corporate Solutions",
    list:
    [
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "Desk and Room Booking Software",
        link: "/solutions/desk-room-booking-software",
      },
      {
        title: "Lease Administration & Accounting",
        link: "/solutions/lease-administration-accounting-software",
      },
      {
        title: "Event Management",
        link: "/solutions/event-management-software",
      },
      {
        title: "Space Planning",
        link: "/solutions/space-planning-software",
      },
      {
        title: "Facility Asset Management",
        link: "/solutions/facility-asset-management-software",
      }
    ],
  },
  {title: "Manufacturing, Industrial  And Utilities Solutions",
  list:
    [
      {
        title: "Enterprise Asset Management",
        link: "/solutions/enterprise-asset-management-software",
      },
      {
        title: "CMMS",
        link: "/solutions/cmms-software",
      },
      {
        title: "Lease Administration & Accounting",
        link: "/solutions/lease-administration-accounting-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "Field Service Management",
        link: "/solutions/field-service-management-software",
      },
      {
        title: "Engineering Document Management",
        link: "/solutions/engineering-document-management-software",
      },
      {
        title: "IoT Remote Monitoring",
        link: "/solutions/iot-remote-monitoring-software",
      },
    ],
  },
  {title: "Retail Solutions",
  list:
    [
      {
        title: "Facility Asset Management",
        link: "/solutions/facility-asset-management-software",
      },
      {
        title: "Lease Administration & Accounting",
        link: "/solutions/lease-administration-accounting-software",
      },
      {
        title: "Enterprise Refrigeration Management",
        link: "/solutions/enterprise-refrigeration-management-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "Project Management",
        link: "/solutions/project-management-software",
      },
      {
        title: "IoT Remote Monitoring",
        link: "/solutions/iot-remote-monitoring-software",
      },
    ],
  },
  {title: "Healthcare Solutions",
  list:
    [
      {
        title: "Enterprise Refrigeration Management",
        link: "/solutions/enterprise-refrigeration-management-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "IoT Remote Monitoring",
        link: "/solutions/iot-remote-monitoring-software",
      },
      {
        title: "CMMS",
        link: "/solutions/cmms-software",
      },
    ],
  },
  {title: "Higher Education  Solution",
  list:
    [
      {
        title: "Desk and Room Booking Software",
        link: "/solutions/desk-room-booking-software",
      },
      {
        title: "Facility Asset Management",
        link: "/solutions/facility-asset-management-software",
      },
      {
        title: "Project Management",
        link: "/solutions/project-management-software",
      },
      {
        title: "IoT Remote Monitoring",
        link: "/solutions/iot-remote-monitoring-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      },
      {
        title: "Event Management",
        link: "/solutions/event-management-software",
      },
      {
        title: "Energy Sustainability Management",
        link: "/solutions/energy-sustainability-management-software",
      },
    ],
  },
  {title: "Real Estate Projects And Leasing Solutions",
  list:
    [
      {
        title: "Lease Administration & Accounting",
        link: "/solutions/lease-administration-accounting-software",
      },
      {
        title: "Space Planning",
        link: "/solutions/space-planning-software",
      },
      {
        title: "Field Service Management",
        link: "/solutions/field-service-management-software",
      },
      {
        title: "Energy Management",
        link: "/solutions/energy-management-software",
      }
    ],
  }
  ];

  export const allSolutionCTA =  {title: "See All Our Solutions >", link: "/solutions"}


  // Products

  export const products = {
    title: "Our Products",
    description:"Purpose-built software solutions to help you unify your built environment",
    cta: {title: "See All Our Products", link: "/products"},
    list: [
      {
        logo: (
          <StaticImage
            src={lucernexLogo}
            alt="lucernex-logo"
            loading="lazy"
            height={52}
            quality={80}
            decoding = "async"
          />
        ),
        title: "Lucernex",
        description:
          "Market-leading real estate software solutions manage lease, site planning and construction complexities. ",
        link: "/products/lucernex",
      },
      {
        logo: (
          <StaticImage
            src={mcLogo}
            alt="mc-logo"
            loading="lazy"
            height={52}
            quality={80}
            decoding = "async"
          />
        ),
        title: "Maintenance Connection",
        description:
          "CMMS software reduces downtime, predicts maintenance needs and manages asset and equipment inventory.",
        link: "/products/maintenance-connection",
      },
      {
        logo: (
          <StaticImage
            src={emsLogo}
            className="ems-logo"
            alt="ems-logo"
            loading="lazy"
            height={52}
            quality={80}
            decoding = "async"
          />
        ),
        title: "EMS",
        description:
          "Workplace management software simplifies desk, meeting and resource scheduling for your office or campus.",
        link: "/products/ems",
      },
      {
        logo: (
          <StaticImage
            src={redEyeLogo}
            alt="redEye-logo"
            loading="lazy"
            height={52}
            quality={80}
            decoding = "async"
          />
        ),
        title: "RedEye",
        description:
          "Engineering document management system streamlines compliance and maximizes company-wide collaboration.",
        link: "/products/redeye",
        onlyUs: true
      },
    ]
  }


  // Action Bar


export const actionBarUS = [
  {
    icon: (
      <svg
        class="svg-inline--fa fa-bullseye-pointer tw-w-[72px] tw-h-[72px]"
        data-prefix="far"
        data-icon="bullseye-pointer"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M256 464c114.9 0 208-93.1 208-208s-93.1-208-208-208S48 141.1 48 256c0 5.5 .2 10.9 .6 16.3L1.8 286.1C.6 276.2 0 266.2 0 256C0 114.6 114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256c-10.2 0-20.2-.6-30.1-1.8l13.8-46.9c5.4 .4 10.8 .6 16.3 .6zm-2.4-48l14.3-48.6C324.2 361.4 368 313.8 368 256c0-61.9-50.1-112-112-112c-57.8 0-105.4 43.8-111.4 100.1L96 258.4c0-.8 0-1.6 0-2.4c0-88.4 71.6-160 160-160s160 71.6 160 160s-71.6 160-160 160c-.8 0-1.6 0-2.4 0zM39 308.5l204.8-60.2c12.1-3.6 23.4 7.7 19.9 19.9L203.5 473c-4.1 13.9-23.2 15.6-29.7 2.6l-28.7-57.3c-.7-1.3-1.5-2.6-2.5-3.7l-88 88c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l88-88c-1.1-1-2.3-1.9-3.7-2.5L36.4 338.2c-13-6.5-11.3-25.6 2.6-29.7z"
        ></path>
      </svg>
    ),
    title: "Explore Our Interactive Demos for a Hands-On Product Tour Now",
    linkTitle: "Dive into Innovation",
    linkPath: "/demo",
  },
  {
    icon: (
      <svg
        class="svg-inline--fa tw-w-[72px] tw-h-[72px]"
        data-prefix="far"
        data-icon=""
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M224 32C135.6 32 64 103.6 64 192v16c0 8.8-7.2 16-16 16s-16-7.2-16-16V192C32 86 118 0 224 0S416 86 416 192v16c0 61.9-50.1 112-112 112H240 224 208c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32h32c44.2 0 80-35.8 80-80V192c0-88.4-71.6-160-160-160zm0 32c70.7 0 128 57.3 128 128c0 13.9-2.2 27.3-6.3 39.8C337.4 246.3 321.8 256 304 256h-8.4c15.2-17 24.4-39.4 24.4-64c0-53-43-96-96-96s-96 43-96 96c0 24.6 9.3 47.1 24.5 64.1c-5.4 9.4-8.5 20.3-8.5 31.9c0 1.4 0 2.7 .1 4C114.8 268.6 96 232.5 96 192c0-70.7 57.3-128 128-128zM208 352h16 16 64 9.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352H208zm105.6 32H134.4C80 384 35.5 426.4 32.2 480H415.8c-3.3-53.6-47.8-96-102.2-96z"
        ></path>
      </svg>
    ),
    title: "Ready for Results? Speak With Our Sales Team Today",
    linkTitle: "Schedule a Discovery Call",
    linkPath: "/calendar-scheduling",
  },
  {
    icon: (
      <svg
        class="svg-inline--fa tw-w-[72px] tw-h-[72px]"
        data-prefix="far"
        data-icon=""
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M352 160V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V160H352zm0-32H32V64c0-17.7 14.3-32 32-32H320c17.7 0 32 14.3 32 32v64zm32 0V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v64 16 16V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160 144 128zM72 224a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm72-72a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm72-72a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM264 416a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zM80 400c-8.8 0-16 7.2-16 16s7.2 16 16 16H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"
        ></path>
      </svg>
    ),
    title: "Get Your FREE Pricing Estimate With Our Online Calculator Now",
    linkTitle: "Unlock Your Savings",
    linkPath: "/pricing-calculator",
  },
];
export const actionBarUK = [
  {
    icon: (
      <svg
        class="svg-inline--fa fa-bullseye-pointer tw-w-[72px] tw-h-[72px]"
        data-prefix="far"
        data-icon="bullseye-pointer"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M256 464c114.9 0 208-93.1 208-208s-93.1-208-208-208S48 141.1 48 256c0 5.5 .2 10.9 .6 16.3L1.8 286.1C.6 276.2 0 266.2 0 256C0 114.6 114.6 0 256 0S512 114.6 512 256s-114.6 256-256 256c-10.2 0-20.2-.6-30.1-1.8l13.8-46.9c5.4 .4 10.8 .6 16.3 .6zm-2.4-48l14.3-48.6C324.2 361.4 368 313.8 368 256c0-61.9-50.1-112-112-112c-57.8 0-105.4 43.8-111.4 100.1L96 258.4c0-.8 0-1.6 0-2.4c0-88.4 71.6-160 160-160s160 71.6 160 160s-71.6 160-160 160c-.8 0-1.6 0-2.4 0zM39 308.5l204.8-60.2c12.1-3.6 23.4 7.7 19.9 19.9L203.5 473c-4.1 13.9-23.2 15.6-29.7 2.6l-28.7-57.3c-.7-1.3-1.5-2.6-2.5-3.7l-88 88c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l88-88c-1.1-1-2.3-1.9-3.7-2.5L36.4 338.2c-13-6.5-11.3-25.6 2.6-29.7z"
        ></path>
      </svg>
    ),
    title: "Explore Our Interactive Demos for a Hands-On Product Tour Now",
    linkTitle: "Dive into Innovation ",
    linkPath: "/demo",
  },
  {
    icon: (
      <svg
        class="svg-inline--fa tw-w-[72px] tw-h-[72px]"
        data-prefix="far"
        data-icon=""
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M224 32C135.6 32 64 103.6 64 192v16c0 8.8-7.2 16-16 16s-16-7.2-16-16V192C32 86 118 0 224 0S416 86 416 192v16c0 61.9-50.1 112-112 112H240 224 208c-17.7 0-32-14.3-32-32s14.3-32 32-32h32c17.7 0 32 14.3 32 32h32c44.2 0 80-35.8 80-80V192c0-88.4-71.6-160-160-160zm0 32c70.7 0 128 57.3 128 128c0 13.9-2.2 27.3-6.3 39.8C337.4 246.3 321.8 256 304 256h-8.4c15.2-17 24.4-39.4 24.4-64c0-53-43-96-96-96s-96 43-96 96c0 24.6 9.3 47.1 24.5 64.1c-5.4 9.4-8.5 20.3-8.5 31.9c0 1.4 0 2.7 .1 4C114.8 268.6 96 232.5 96 192c0-70.7 57.3-128 128-128zM208 352h16 16 64 9.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352H208zm105.6 32H134.4C80 384 35.5 426.4 32.2 480H415.8c-3.3-53.6-47.8-96-102.2-96z"
        ></path>
      </svg>
    ),
    title: "Ready for Results? Speak With Our Sales Team Today",
    linkTitle: "Schedule a Discovery Call",
    linkPath: "/uk/contact-us",
  },
  {
    icon: (
      <svg
        class="svg-inline--fa tw-w-[72px] tw-h-[72px]"
        data-prefix="far"
        data-icon=""
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 384 512"
        data-fa-i2svg=""
      >
        <path
          fill="currentColor"
          d="M352 160V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V160H352zm0-32H32V64c0-17.7 14.3-32 32-32H320c17.7 0 32 14.3 32 32v64zm32 0V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v64 16 16V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160 144 128zM72 224a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm72-72a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm72-72a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zm24 72a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM264 416a24 24 0 1 0 48 0 24 24 0 1 0 -48 0zM80 400c-8.8 0-16 7.2-16 16s7.2 16 16 16H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H80z"
        ></path>
      </svg>
    ),
    title: "Get Your FREE Pricing Estimate With Our Online Calculator Now",
    linkTitle: "Unlock Your Savings",
    linkPath: "/pricing-calculator",
  },
];

// content Logos

export const contentLogoUS = [
  {
    number: '85%',
    title: 'The company has achieved an 85% planned maintenance to 15% unplanned maintenance split.',
    logo: <StaticImage src={gallery} alt="gallery" loading="lazy" width={50} quality={100} decoding = "async" />,
    class: "essex-logo"
  },
  {
    number: '24/7',
    title: 'Online maintenance request system to support an increasing number of users.',
    logo: <StaticImage src={hospital} alt="hospital" loading="lazy" width={88} quality={70} decoding = "async" />,
  },
  {
    number: '$1 million',
    title: 'The company saved 45-55 cents per square foot — totaling $1 million in savings annually.',
    logo: <StaticImage src={Minnesota} alt="Minnesota" loading="lazy" width={88} quality={100} decoding = "async" />,
  },
  {
    number: '25%',
    title: 'Reduction in equipment downtime and given the company time back to focus on customer experience.',
    logo: <StaticImage src={chubbys} alt="chubbys" loading="lazy" width={88} quality={70} decoding = "async" />,
  }
]

export const contentLogoUK = [
  {
    number: '300',
    title: 'SSO has saved the helpdesk an estimated 300 hours per annum.',
    logo: <StaticImage src={essex} alt="gallery" loading="lazy" width={88} quality={100}  decoding = "async"/>,
    class: "essex-logo"
  },
  {
    number: '+20,000',
    title: 'Real-time relationship between assets and over 20,000 documents.',
    logo: <StaticImage src={basf} alt="hospital" loading="lazy" width={88} quality={100} decoding = "async" />,
  },
  {
    number: ' €3 million',
    title: 'Reduced document exchange costs by €3 million per year.',
    logo: <StaticImage src={total} alt="Minnesota" loading="lazy" width={152}  quality={100} decoding = "async"/>,
  },
  {
    number: '+95%',
    title: 'Planned Preventative Maintenance (PPM) increased to 95%+ certainty.',
    logo: <StaticImage src={coop} alt="chubbys" loading="lazy" width={88} quality={100} decoding = "async" />,
  }
]


export const videoData = {
  title: "What Makes Accruent a Market Leader?",
   videoUrl: "https://accruent.wistia.com/medias/lzvtb14zze",
}
