import React from 'react';
import loadable from '@loadable/component';
const Video = loadable(() => import("src/components/commonComponents"), {
    resolveComponent: (components) => components.Video
  });

/**
 * list of Statistics
 * @param {Object} data -(required) { title: string, class: string, videoUrl: string}
 *
 */

export const VideoSection = ({ data }) => {
  return (
    <div className='tw-mx-4 tw-text-center'>
      <h2 className="title-4">{data?.title}</h2>
      <div className='tw-mt-7 lg:tw-ml-[5rem] lg:tw-mr-[5rem] lg:tw-min-h-[540px] tw-min-h-[164px]'>
      <Video videoUrl={data?.videoUrl} />
      </div>
    </div>
  );
}

