import React, { useState } from "react";
import { IndustryCards } from "src/components/home/industryCards";
import { SolutionList } from "../solutionList";
import * as heroStyles from "./_newHomeHero.module.scss";
import {
  heroData,
  industryCards,
  solutionsListUS,
  solutionsListUK,
  allSolutionCTA,
} from "src/rawData/homepage";
import { Picture } from "src/components/commonComponents" ;

export const NewHomeHero = ({ locale, ...data }) => {
  const [active, setActive] = useState(0);
  let solutionsList = locale == "en-gb" ? solutionsListUK : solutionsListUS;
  solutionsList = solutionsList?.[active];
  const linkPrefix = locale == "en-gb" ? "/uk" : "";

  return (
    <div className={heroStyles?.newHomeHero + ' tw-relative tw-bg-accruent_sapphire md:tw-bg-transparent'}>
      <Picture
       image={data?.heroImagesData?.[data.heroImagePaths?.[active]]}
       className="hero-bg-img tw-w-full tw-h-full tw-object-cover tw-absolute tw-hidden md:tw-block tw-object-[50%_bottom]"
       rel="preload"
       alt="hero-image"
      />
      <div className="new-home-hero container">
        <div className="row tw-relative tw-pt-[76px] tw-pb-[32px] lg:tw-py-[76px] tw-px-[16px] ">
          <h1 className="hero-title-1 tw-w-full tw-text-[36px] md:tw-text-[56px] tw-text-accruent_white tw-leading-[39.44px] md:tw-leading-[62px] tw-tracking-[0.31px] md:tw-tracking-[0.5px] lg:tw-w-[102%]">{heroData?.title}</h1>
          <p className="tw-text-pxSize5 tw-mt-2 tw-mb-0 tw-w-full tw-text-accruent_white">{heroData?.description}</p>
          <section className="industry-section tw-mt-[2rem]">
            <IndustryCards
              data={industryCards}
              active={active}
              setActive={setActive}
            />
          </section>
          <section className="solution-section tw-mt-[30px]">
            <SolutionList
              data={solutionsList}
              cta={allSolutionCTA}
              linkPrefix={linkPrefix}
            />
          </section>
        </div>
      </div>
    </div>
  );
};
