import { useStaticQuery, graphql } from "gatsby";
export const useHomeHeroImages = () => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          relativePath: {
            in: [
              "home/Herobgs/corporate.jpg"
              "home/Herobgs/manufacturing.jpg"
              "home/Herobgs/retail.jpg"
              "home/Herobgs/healthcare.jpg"
              "home/Herobgs/higheducation.jpg"
              "home/Herobgs/realestate.jpg"
            ]
          }
        }
      ) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImage: gatsbyImageData(
              placeholder: NONE,
              width:1000,
              quality: 70
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  `);

  return images?.nodes?.reduce((imagesObj,node)=>{  imagesObj[node.relativePath] = node.childImageSharp; return imagesObj;}, {});
};
