import React from "react";
import angleDown from "../../../static/angle-down.svg";
/**
 * list of cards with image, title having active and deactive state
 * @param {Array} data -(required) [{title: string, color: image, white:image }] 
 * @param {Number} active - (required) index of active item in list
 * @param {Function} setActive -method to set active index
 * 
 */

export const IndustryCards = ({ data, active, setActive }) => {
  return (
    <div className="industry-carrds tw-grid tw-grid-cols-6 tw-gap-4 tw-pb-[29px] tw-overflow-y-auto">
      {data.map((item, i) => {
        return (
          <div
          key={i}
          className={`industry-card tw-bg-rectangle_card_bg tw-cursor-pointer tw-shadow-[0px_6px_12px_#00000029] 
          tw-rounded-[12px] tw-w-[180px] lg:tw-w-full tw-min-h-[152px] tw-text-center tw-flex tw-justify-center tw-items-center tw-flex-col 
          tw-p-[11px] tw-relative hover:tw-bg-accruent-white hover:tw-border-2 tw-border-solid tw-border-tab-hover-color
          ${i === active ? "active !tw-bg-accruent-white tw-border-2 tw-border-solid tw-border-tab-hover-color" : ""}`}
          onClick={() => {
            setActive(i);
          }}
          >
            <img
              className="color-image tw-hidden tw-w-[63px] tw-h-[58px] tw-image"
              alt={item.title + "-color-logo"}
              src={item.color}
              width={63}
              height={58}
              decoding = "async"
            />
            <img
             className="white-img tw-block tw-w-[63px] tw-h-[58px] tw-image"
             alt={item.title + "-white-logo"}
             src={item.white}
             width={63}
             height={58}
              decoding = "async"
            />
            <div className="tw-font-Poppins md:tw-text-lgFont tw-text-mobileLgFont industry-title !tw-text-[1rem] !tw-tracking-[0.14px] !tw-leading-[1rem] tw-mt-4 tw-text-accruent_white">{item.title}</div>
            {i === active && (
              <img src={angleDown} decoding="async" className="arrow tw-h-[24px] tw-bottom-[-30px] tw-absolute tw-text-accruent_white"/>
              // <svg className="arrow tw-h-[24px] tw-bottom-[-30px] tw-absolute tw-text-accruent_white" role="img" viewBox="0 0 448 512">
              //   <path
              //     fill="currentColor"
              //     d="M241 337c-9.4 9.4-24.6 9.4-33.9 0L47 177c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L241 337z"
              //   ></path>
              // </svg>
            )}
          </div>
        );
      })}
    </div>
  );
};
