import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { LocalizedLink } from "../commonComponents/localizedLink";

const bgImage = "../../../src/images/Insights-Banner-Homepage/insights-bg.jpg";
const logoImage = "../../../src/images/Insights-Banner-Homepage/Accruent-Insights-2024-Logo_Inverted.png";
const ingishtsImage = "../../../src/images/Insights-Banner-Homepage/Insights-img.jpg";


const InsightsHero = ({locale}) => {
  const link = locale ==='en-gb' ? "https://www.accruent.com/events/accruent-insights" :"/events/accruent-insights";
  return (
    <div className="container section">
      <div className="tw-rounded-[32px] tw-box-shadow-insights tw-relative md:tw-pt-[20px]">
        <div>
        <StaticImage 
          src={bgImage}
          quality={100}
          className="tw-w-full tw-h-full tw-rounded-[32px] !tw-absolute"
          />
          </div>
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-p-[42px] tw-gap-[20px]">
          <div className="tw-w-full md:tw-w-1/2 tw-z-[1] ">
            <div className="tw-mb-[32px]">
              <StaticImage 
              src={logoImage}
              quality={100}
              className="tw-w-[220px]"
              alt="Accruent Insights logo"
              />
            </div>
            <div className="tw-mb-[20px] md:tw-mb-[32px]">
              <h2 className=" md:!tw-text-[40px] tw-pb-[4px] tw-text-accruent_white">Accruent Insights 2024 is here!</h2>
              <p className="subtitle-2 !tw-text-accruent_white tw-mb-0">Software Solutions for Corporate, Healthcare, Retail, Higher Education, Manufacturing, Energy and Real Estate and more</p>
            </div>
            <LocalizedLink to={link} className="tw-hidden tw-bg-accruent_white tw-rounded-[12px] md:tw-inline-flex">
              <span className="tw-p-[14px_24px_14px_24px] tw-text-[16px] tw-text-accruent_sapphire tw-font-bold">Register Now</span>
            </LocalizedLink>
           
          </div>
          <div className="tw-w-full md:tw-w-1/2 tw-z-[1]">
            <StaticImage 
              src={ingishtsImage}
              quality={100}
              className="tw-h-[130px] md:tw-h-[342px] tw-rounded-[24px]"
            />
            <LocalizedLink to={link} className=" tw-bg-accruent_white tw-rounded-[12px] tw-flex tw-mt-[24px] md:tw-hidden">
              <span className="tw-m-auto tw-p-[14px_24px_14px_24px] tw-text-[16px] tw-text-accruent_sapphire tw-font-bold">Register Now</span>
            </LocalizedLink>
          </div>
        </div>
      </div>

    </div>
  )
}
export default InsightsHero;