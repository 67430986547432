import React from "react";
import { LocalizedLink } from "src/components/commonComponents";
/**
 * list of links in card view
 * @param {Object} data -(required) {title: string, list:[{title: string, link: string}]} 
 * @param {Object} cta - (required) {title: string, link: string}
 * @param {string} linkPrefix - (required) locale specific prefix.
 * 
 */

export const SolutionList = ({ data, cta, linkPrefix }) => {

  return (
    <div className={"solution-box"}>
      <div className="box-header  tw-flex tw-justify-between">
        <h2 className="md:tw-text-xlFont tw-text-mobileXlFont tw-text-accruent_white md:tw-text-accruent_sapphire"> {data?.title} </h2>
        {cta?.link && (
          <LocalizedLink to={linkPrefix+cta.link} className="tw-font-Poppins hover:tw-text-accruent_text_black tw-font-bold tw-leading-[27.84px] tw-tracking-[0.14px] tw-hidden md:tw-inline-block tw-text-accruent_sapphire ">
            {cta.title}
          </LocalizedLink>
        )}
      </div>
      <div className="box-body tw-mt-4 tw-grid tw-gap-4 tw-overflow-y-auto">
        {data?.list?.map((item, i) => {
          return (
            <LocalizedLink
              to={linkPrefix + item?.link}
              key={i}
              className="scroll-list-item tw-bg-accruent_white tw-rounded-[12px] tw-p-[12px] tw-pl-4 tw-font-Roboto 
              tw-text-[14px] tw-leading-[18px] tw-tracking-[0.25px] tw-flex tw-items-center tw-justify-between tw-min-h-[61px]
              tw-gap-[12px] tw-font-bold tw-min-w-[278px] md:tw-min-w-full tw-text-accruent_sapphire
             "
            >
              <span>{item?.title}</span> <span className="list-arrow tw-font-Poppins tw-text-[1rem] tw-leading-[27.84px] tw-tracking-[0.14px] tw-font-medium">{" >"}</span>
            </LocalizedLink>
          );
        })}
      </div>
      {cta?.link && (
        <LocalizedLink to={linkPrefix+cta.link} className="footer-link tw-font-Poppins tw-text-[1rem] tw-font-bold tw-text-accruent_white tw-leading-[27.84px] tw-tracking-[0.14px] tw-w-full tw-block tw-text-center tw-mt-[43px] md:tw-hidden">
          {cta.title}
        </LocalizedLink>
      )}
    </div>
  );
};
