import React from "react";
import getBannerData from "src/components/helpers/getBannerData";
import { schemaMarkupRendering } from "../../helpers/schemaMarkupRendering";
import { LocalizedLink, Banner, LCPImage, Picture,PopupVideo } from "src/components/commonComponents";
const getImageObject = require("src/../helpers/getImageObject");

const HomeHero = ({heroEmsData, banner}) => {

  const emsHeroData = heroEmsData?.[0]
  const heroVideoUrl = emsHeroData?.relationships?.fieldHeroVideo2?.fieldMediaVideoEmbedField
  const thumbnail =   getImageObject(emsHeroData?.relationships?.fieldHeroVideo2?.relationships?.thumbnail, emsHeroData?.relationships?.fieldHeroVideo2?.thumbnail)
  const bannerData = getBannerData(banner);
  const bgImage = getImageObject(
    emsHeroData?.relationships?.fieldBackgroundImage,
    {},
    "fieldBackgroundImage"
  );
        
  return (
    <div className="is-relative">
      <section
        className="section hero is-block is-halfheight ems-home-hero"
      >
      {bgImage?.gatsbyImage &&  <Picture image={bgImage} className="ems-hero-foreground-image"/> }
        {schemaMarkupRendering(emsHeroData)}
        <div className="container">
          <div className="columns hero-content">
            <div className="column is-7 is-full-tablet hero-left-container">
              <h1 className="mb-3 tw-w-[94%]">{emsHeroData?.title}</h1>
              <p
                className="content"
              >

              {emsHeroData?.fieldHeroDescription}
              </p>

              <div className="is-flex ems-hero-buttons">
                {emsHeroData?.fieldHeroCtaUrl?.title && <LocalizedLink
                  to={emsHeroData?.fieldHeroCtaUrl?.url}
                  className="button is-medium is-primary has-text-white "
                >
                  {emsHeroData?.fieldHeroCtaUrl?.title}
                </LocalizedLink>}
                {emsHeroData?.fieldHeroSecondaryCta?.title && <LocalizedLink
                  to={emsHeroData?.fieldHeroSecondaryCta?.url}
                  className="button is-medium is-outlined is-accruent-sapphire has-text-accruent-sapphire "
                >
                  {emsHeroData?.fieldHeroSecondaryCta?.title}
                </LocalizedLink>}

              </div>
            </div>

            <div className="column is-5 is-hidden-mobile ems-video-container">
              {heroVideoUrl &&
                <PopupVideo videoUrl={heroVideoUrl} thumbnail={thumbnail} thumbnailClass={"p-0 ems-hero-video"} />
              }
            </div>
          </div>
        </div>
        {bannerData?.fieldShowReportBanner && (
          <div className="container">
            <div className="columns">
              <Banner
                {...bannerData}
              />
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
 
const AboutUs = (props) => {
  const { heading, preHeading, body, cta, secondaryCta, heroImage } = props;

  return (
    <>
     <section
        className="section hero is-block is-halfheight ems-about-hero tw-ems-about-hero"
      >
        <LCPImage/>
       <div className="banner-left-graphic">
        <div className="container">
          <div className="columns">
            <div className="column is-9-desktop is-full-tablet ems-hero-container">
              {preHeading &&
                <div className="pre-heading">{preHeading}</div>
              }
              {!!heading && <h1 className="title title-2">{heading}</h1>}
              {!!body && (
                <div
                  className="content dangerously-html-handle"
                  dangerouslySetInnerHTML={{
                    __html: body?.value,
                  }}
                />
              )}
              <div className="is-flex product-hero-button">
                {!!cta && (
                  <LocalizedLink
                    to={cta.url}
                    className="button is-medium is-primary has-text-white"
                  >
                    {cta.title}
                  </LocalizedLink>
                )}
                {!!secondaryCta && (
                  <LocalizedLink
                    to={secondaryCta.url}
                    className="button is-medium is-outlined is-accruent-sapphire has-text-accruent-sapphire"
                  >
                    {secondaryCta.title}
                  </LocalizedLink>
                )}
              </div>
            </div>
          </div>

        </div>
        </div>
      </section>
    </>
  );
}
export const EMSHero = ({ type = undefined, ...rest }) => {

  switch (type) {
    case "about-us":
      return <AboutUs {...rest} />
    default:
      return <HomeHero {...rest}/>

  }
};

