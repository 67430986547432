import React from 'react'
import "src/scss/components/_actionBar.scss";
import { LocalizedLink } from '../commonComponents';

/**
 * list of Action Data
 * @param {Array} data -(required) [{icon: svgIcon, title: string, linkPath: string, linkTitle: string}]
 *
 */

const ActionBar = ({ data }) => {
  return (
    <div className='accruent-action-bar  tw-text-accruent_white'>
      <div className='accruent-action-bar-container tw-container container tw-flex tw-flex-wrap md:tw-flex-nowrap tw-pt-[52px] tw-pb-[32px] tw-m-auto tw-justify-center tw-mx-auto'>
        {data?.map((bar,i)=>{
          return(
            <div className='md:tw-w-1/3 tw-basis-full tw-relative tw-mb-4 md:tw-mb-0 tw-mx-4 md:tw-mx-1.5 tw-rounded-3xl tw-accruent_action_bar_gradient md:tw-min-h-[273px]'>
              <div className={`action-bar-border tw-absolute tw-top-[-4px] md:tw-top-[-3px] tw-left-[30px] tw-border-solid tw-border-[4px] tw-w-[50px] tw-h-[0px] tw-rounded-md`}></div>
              <div key={i} className='tw-flex tw-flex-col tw-justify-between tw-items-center tw-min-h-full'>
                <div className='tw-mt-[32px] tw-w-[72px] tw-h-[72px]'>
                  {bar.icon}
                </div>
                <div className='tw-pt-4 tw-pb-8'>
                  <div className='tw-px-8 tw-text-center tw-font-Roboto tw-text-actionBarTextDesktopFont'>{bar.title}</div>
                </div>
                <div className='tw-rounded-xl tw-py-3.5 tw-px-6 tw-bg-blue_tag tw-mb-[20px]'>
                  <LocalizedLink to={bar.linkPath} className="tw-text-accruent_white hover:tw-text-accruent_white  tw-font-Poppins tw-text-actionBarCTADesktopFont" >{bar.linkTitle}</LocalizedLink>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      </div>
  )
}

export default ActionBar