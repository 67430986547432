import React from "react";
import "src/scss/newhomepage/_newHomepage.scss";
import { LocalizedLink } from "src/components/commonComponents";
import Helmet from "react-helmet";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import loadable from '@loadable/component'
import { NewHomeHero } from "src/components/home/hero";
import { ProductCards } from "src/components/home/productCards";
import { VideoSection } from "src/components/home/VideoSection";
import { heroImagePaths, products, actionBarUS, actionBarUK, contentLogoUS, contentLogoUK, videoData } from "src/rawData/homepage";
import InsightsHero from "src/components/home/InsightsHero";
import ActionBar from "src/components/home/actionBar";
const SEO = loadable(() => import('src/components/commonComponents'),{resolveComponent: (components) => components.SEO});
import { useHomeHeroImages } from "src/hooks/useHomeHeroImages";
import { getPageDataJsonPath } from "src/utils";
const CircleContent = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.CircleContent
 });
const RecentResources = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.RecentResources
 });
const StatisticsSlider = loadable(() => import("src/components/home/statisticsSlider"),{resolveComponent: (components) => components.StatisticsSlider});


const AccruentHomepage = ({ pageContext, path }) => {
  const { locale } = pageContext;
  const contentLogo = locale=='en-gb'?contentLogoUK:contentLogoUS
  const actionBarData = locale=='en-gb'?actionBarUK :actionBarUS
  const OGMarkup = getOGMarkup('Accruent','homepage');
  const linkPrefix = locale == "en-gb"?'/uk':''
  const heroImages = useHomeHeroImages();

  const heroImageAttrs= heroImages?.[heroImagePaths[0]]?.gatsbyImage?.images?.fallback
  const pageDataPath = getPageDataJsonPath('/index')

  return (
    <>
     <SEO
        lang={locale}
        pageUrl={(locale == 'en-gb') ? '/uk' : '/'}
        meta = {(locale == 'en-gb') ? [{name: 'description', content: 'Accruent provides comprehensive software solutions for your facilities, assets, and people to help drive effortless customer experiences, maximize growth, and unlock your business full potential.'}] : [{name: 'description', content: 'Accruent\'s software helps workplace & asset management organizations unify their built environments for better management of people, places, and resources.'}]}
        alternatives={[
          {
            href: "",
            hreflang: "en"
          },
          {
            href: "/uk",
            hreflang: "en-gb"
          },
          {
            href: "",
            hreflang: "x-default"
          }
        ]}
        OGMarkup={OGMarkup}
        heroImage={heroImages?.[heroImagePaths[0]]}
        preloads= {[pageDataPath]}       
      />
      <Helmet>
        <meta
          property="og:image"
          content="https://www.accruent.com/accruent-social-share.png"
        />
      </Helmet>

    <div className="homepage-new-version">
      <NewHomeHero heroImagesData={heroImages} heroImagePaths={heroImagePaths}  locale={locale} />
      <ActionBar data={actionBarData} />
      {locale !=='en-gb' && <InsightsHero locale={locale}/>}
      <div className="container">
      <section className="tw-mt-[52px] md:tw-mt-[76px] tw-p-0">
         <VideoSection data={videoData}/>
      </section>
      <section className="tw-mt-[52px] md:tw-mt-[76px] tw-p-0">
      <ProductCards data={products} linkPrefix={linkPrefix} locale={locale}/>
      </section>

        {/* Statistics slider  */}
        <StatisticsSlider data={contentLogo} />
        {/* <CircleContent locale={locale} /> */}
          <div className="version-resource-cards tw-version-resource-cards content-visibility-auto-mobile tw-mt-[77px] tw-mb-[76px] tw-py-6 tw-px-4">
            <div className="resource-heading tw-flex tw-items-center tw-justify-between tw-mb-[34px]">
              <h3 className="tw-text-xxlFont tw-text-accruent_heading_color tw-font-Poppins">The Latest and Greatest</h3>
              <LocalizedLink to={linkPrefix + '/resources'} className="tw-text-accruent_sapphire tw-font-Poppins tw-font-bold tw-text-base tw-leading-4 tw-py-[14px] tw-px-6 tw-border-2 tw-border-solid tw-border-accruent_sapphire tw-rounded-xl tw-whitespace-nowrap hover:tw-bg-accruent_dark_imp_blue hover:tw-text-accruent_white hover:tw-border-accruent_dark_imp_blue" >See All</LocalizedLink>
          </div>
          <RecentResources titleClass={"is-hidden"} heading={""} locale={locale} />
        </div>
      </div>


    </div>
    </>
  )
}
export default AccruentHomepage;